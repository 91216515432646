import './app.scss';
import domready from 'domready';
import { loadCss } from './utilities/js/default-css-loader';
import 'lazysizes';
import { loadLazyImages } from './utilities/js/lazy-image-loader';
import { bindTrackingClick } from './utilities/js/tracking';
import { EventScroller } from './utilities/js/event-scroller';
import { ResizeHandler } from './utilities/js/resize-handler';
import { Anchor } from './areas/anchor/anchor';
import { loadYoutubeApi } from './utilities/js/helper';

// import libs and components

// GLOBAL OPTIONS
const OPTIONS = { // eslint-disable-line
    breakpoints: {
        s: 320,
        m: 768,
        l: 1024,
        xl: 1200,
        xxl: 1440,
        xxxl: 1920
    }
};

const eventScroller = new EventScroller();
const resizeHandler = new ResizeHandler();

window.youtubeApi = false;
window.youtubeApiLoading = false;
window.krpanoApi = false;

/**
 * define function for initialising modules
 */

const initContent = ($content) => {
    console.log('start app');
    bindTrackingClick($content);

    loadLazyImages();

    if (!document.querySelector('body').classList.contains('is--editmode')) {
        const $scrollTables = $content.querySelectorAll('table');
        if ($scrollTables.length > 0) {
            import('./components/scrolltable/scrolltable').then(({ Scrolltable }) => {
                for (let i = 0; i < $scrollTables.length; i++) {
                    const $scrollTable = $scrollTables[i];
                    const scrollTableAPI = new Scrolltable($scrollTable);
                    $scrollTable.API = scrollTableAPI;
                }
            });
        }
    }

    const $textlinks = $content.querySelectorAll('.textlink, .hoverlink');
    for (let i = 0; i < $textlinks.length; i++) {
        const $textlink = $textlinks[i];
        const origHtml = $textlink.innerHTML;
        const newHtml = '<span>' + origHtml + '</span>';
        $textlink.innerHTML = newHtml;
    }

    const $anchors = $content.querySelectorAll('a[href^="#"], a[href*="#"]');
    for (let i = 0; i < $anchors.length; i++) {
        const anchorAPI = new Anchor($anchors[i]);
        $anchors[i].API = anchorAPI;
    }

    // const $searchForms = $content.querySelectorAll('[data-searchform="root"]');
    // if ($searchForms.length > 0) {
    //     import('./components/search-form/search-form').then(({ SearchForm }) => {
    //         for (let i = 0; i < $searchForms.length; i++) {
    //             const $searchForm = $searchForms[i];
    //             const searchFormAPI = new SearchForm($searchForm);
    //             $searchForm.API = searchFormAPI;
    //         }
    //     });
    // }

    // Google AutoComplete
    const $autoCompleteInputs = document.querySelectorAll('[data-google-autocomplete]');

    if ($autoCompleteInputs.length > 0) {
        import('./utilities/js/google-autocomplete').then(({ GoogleAutoComplete }) => {
            for (let i = 0; i < $autoCompleteInputs.length; i++) {
                new GoogleAutoComplete($autoCompleteInputs[i]); // eslint-disable-line
            }
        });
    }

    const $entrySliders = $content.querySelectorAll('[data-entryslider="root"]');
    if ($entrySliders.length > 0) {
        import('./areas/entry-slider/entry-slider').then(({ EntrySlider }) => {
            for (let i = 0; i < $entrySliders.length; i++) {
                const $entrySlider = new EntrySlider($entrySliders[i], {
                    resizeHandler: resizeHandler
                });
                $entrySlider.initialize();
            }
        });
    }

    // Benefit Slider
    const $benefitSliders = $content.querySelectorAll('[data-benefitslider="root"]');
    if ($benefitSliders.length > 0) {
        import('./areas/benefit-slider/benefit-slider').then(({ BenefitSlider }) => {
            for (let i = 0; i < $benefitSliders.length; i++) {
                const $benefitSlider = $benefitSliders[i];
                const benefitSliderAPI = new BenefitSlider($benefitSlider);
                $benefitSlider.API = benefitSliderAPI;
            }
        });
    }

    // Quote and Video Slider
    const $quoteVideoSliders = $content.querySelectorAll('[data-quote-video-slider="root"]');
    if ($quoteVideoSliders.length > 0) {
        import('./components/quote-video-slider/qv-slider').then(({ QuoteVideoSlider }) => {
            for (let i = 0; i < $quoteVideoSliders.length; i++) {
                const $quoteVideoSlider = new QuoteVideoSlider($quoteVideoSliders[i]);
                $quoteVideoSlider.initialize();
            }
        });
    }

    // Text Icon Slider
    const $textIconSliders = $content.querySelectorAll('[data-texticonslider="root"]');
    if ($textIconSliders.length > 0) {
        import('./areas/text-icon-slider/text-icon-slider').then(({ TextIconSlider }) => {
            for (let i = 0; i < $textIconSliders.length; i++) {
                const $textIconSlider = new TextIconSlider($textIconSliders[i]);
                $textIconSlider.initialize();
            }
        });
    }

    // Tab Slider
    const $tabSliders = $content.querySelectorAll('[data-tab-slider="root"]');
    if ($tabSliders.length > 0) {
        import('./areas/tab-slider/tab-slider').then(({ TabSlider }) => {
            for (let i = 0; i < $tabSliders.length; i++) {
                const $tabSlider = new TabSlider($tabSliders[i]);
                $tabSlider.initialize();
            }
        });
    }

    // Instagram Highlight Slider
    const $instagramSliders = $content.querySelectorAll('[data-instagram-hl-slider="root"]');
    if ($instagramSliders.length > 0) {
        import('./areas/instagram-highlight/instagram-highlight').then(({ InstagramHighlightSlider }) => {
            for (let i = 0; i < $instagramSliders.length; i++) {
                const $instagramSlider = new InstagramHighlightSlider($instagramSliders[i]);
                $instagramSlider.initialize();
            }
        });
    }

    // FAQ Search
    const $faqSearches = $content.querySelectorAll('[data-faq-search="root"]');
    if ($faqSearches.length > 0) {
        import('./areas/faq-search/faq-search').then(({ FaqSearch }) => {
            for (let i = 0; i < $faqSearches.length; i++) {
                const $faqSearch = new FaqSearch($faqSearches[i]);
                $faqSearch.initialize();
            }
        });
    }

    // Icon Steps
    const $iconStepsModules = $content.querySelectorAll('[data-icon-steps="root"]');
    if ($iconStepsModules.length > 0) {
        import('./areas/icon-steps/icon-steps').then(({ IconSteps }) => {
            for (let i = 0; i < $iconStepsModules.length; i++) {
                const $iconStepsModule = new IconSteps($iconStepsModules[i], {
                    resizeHandler: resizeHandler
                });
                $iconStepsModule.initialize();
            }
        });
    }

    const $medias = $content.querySelectorAll('[data-media="root"]');
    if ($medias.length > 0) {
        loadYoutubeApi();

        window.onYouTubeIframeAPIReady = () => {
            import('./components/media/media').then(({ Media }) => {
                for (let i = 0; i < $medias.length; i++) {
                    const $media = $medias[i];
                    const mediaAPI = new Media($media);
                    $media.API = mediaAPI;
                }
            });
        };
    }

    // Contact Persons
    const $contactPersons = document.querySelectorAll('[data-contact="root"]');
    if ($contactPersons) {
        import('./areas/contact/contact').then(({ ContactPerson }) => {
            for (let i = 0; i < $contactPersons.length; i++) {
                new ContactPerson($contactPersons[i]); // eslint-disable-line
            }
        });
    }

    if (typeof window.Modernizr !== 'undefined') {
        if (!window.Modernizr.csshover) {
            const $hovers = $content.querySelectorAll('[data-hover], .textlink, .button');
            const hoverClass = 'hover';

            if ($hovers.length > 0) {
                for (let i = 0; i < $hovers.length; i++) {
                    const $hover = $hovers[i];

                    $hover.addEventListener('touchstart', () => {
                        if (!$hover.classList.contains(hoverClass)) {
                            $hover.classList.add(hoverClass);
                        }
                    });
                }

                document.addEventListener('touchstart', (e) => {
                    const $hoveredElements = $content.getElementsByClassName(hoverClass);

                    for (let i = 0; i < $hoveredElements.length; i++) {
                        const $currentHover = $hoveredElements[i];
                        const isClickInside = $currentHover.contains(e.target);

                        if (!isClickInside) {
                            if ($currentHover.classList.contains(hoverClass)) {
                                $currentHover.classList.remove(hoverClass);
                            }
                        }
                    }
                });
            }
        }
    }
};

/**
 * starting point
 */

domready(function () {
    window.addEventListener('cssLoaded', function () {
        initContent(document.querySelector('body'));

        const $backToTop = document.querySelector('[data-backtotop="root"]');
        if ($backToTop) {
            import('./components/back-to-top/back-to-top').then(({ BackToTop }) => {
                new BackToTop($backToTop, { // eslint-disable-line
                    eventScroller: eventScroller
                });
            });
        }

        /*
        eventScroller.customFunctions.push(() => {
        });
        */

        eventScroller.init();
        resizeHandler.init();
    });

    loadCss();
});

/**
 * usage:
 * in template:
 * <button data-tracking="content_teaser|click|link">track me</button>
 *
 * in js:
 * via eventListener: bindTrackingClick($content);
 *
 * via function call:
 * sendTracking({
        category: 'category example',
        action: 'action example',
        label: `label-example-with_${variable}`
    });
 */

const bindTrackingClick = ($content) => {
    const $trackingElements = $content.querySelectorAll('[data-tracking]');

    const addEvent = ($trackingElement) => {
        $trackingElement.addEventListener('click', () => {
            const trackingData = getTrackingData($trackingElement);

            if (trackingData !== false) {
                sendTracking(trackingData);
            }
        });
    };

    for (let i = 0; i < $trackingElements.length; i++) {
        const $trackingElement = $trackingElements[i];
        addEvent($trackingElement);
    }
};

const getTrackingData = ($trackingElement) => {
    const trackingString = $trackingElement.getAttribute('data-tracking');
    const trackingArray = trackingString.split('|');

    const trackingData = {};
    trackingData.category = trackingArray[0];
    trackingData.action = trackingArray[1];
    trackingData.label = trackingArray[2];
    trackingData.value = trackingArray[3];

    return trackingData;
};

const sendTracking = (data) => {
    const category = data.category;
    const action = data.action;
    const label = data.label;
    const value = data.value;

    if (typeof window.ga !== 'undefined') {
        if (value === undefined) {
            window.ga('send', 'event', category, action, label);
        } else {
            window.ga('send', 'event', category, action, label, value);
        }
    } else {
        if (value === undefined) {
            console.log({
                category: category,
                action: action,
                label: label
            });
        } else {
            console.log({
                category: category,
                action: action,
                label: label,
                value: value
            });
        }
    }
};

export {
    bindTrackingClick,
    sendTracking,
    getTrackingData
};

import { getOffset, smoothScrollTo } from '../../utilities/js/helper';

class Anchor {
    constructor (element, options) {
        const settings = {
            initAttr: 'data-benefitslider',
            contentSliderAttr: 'content',
            tilesSliderAttr: 'tiles'
        };

        this.settings = Object.assign({}, settings, options);
        this.$anchor = element;
        this.anchorHref = this.$anchor.getAttribute('href');
        this.anchorParts = this.anchorHref.split('//');
        this.anchorPath = this.anchorParts[0];
        this.internalPage = false;
        this.currentPage = false;

        if (this.anchorHref === this.anchorPath) {
            this.internalPage = true;

            if (this.anchorPath.indexOf('#') === 0) {
                this.currentPage = true;
            }
        }

        this.initialize();
    }

    initialize () {
        this.setEvents();
    }

    setEvents () {
        if (this.internalPage === this.currentPage === true) {
            this.$anchor.addEventListener('click', (e) => {
                const anchorId = this.anchorHref.split('#')[1];
                const $anchorTarget = document.getElementById(anchorId);

                if (anchorId !== 'uc-corner-modal-show') {
                    if ($anchorTarget !== null) {
                        const anchorDimensions = getOffset($anchorTarget);
                        const anchorMovement = anchorDimensions.top;

                        smoothScrollTo(0, anchorMovement, 400, () => {
                            if (anchorId !== 'top') {
                                window.location.hash = anchorId;
                            }
                        });
                    } else {
                        smoothScrollTo(0, window.innerHeight, 1000);
                    }
                }

                e.preventDefault();
            });
        }
    }
}

export { Anchor };
